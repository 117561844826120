<template>
  <div class="wrapper">
    <Header></Header>
    <div class="body">
      <div class="home1">
        <img class="img" :src="img1">
        <div class="reserve" @click="reserve">立即预约</div>
      </div>
      <!--纵向框-->
      <div class="link">
        <div class="title">
          <div class="title-big">全购热门产品</div>
          <div class="title-small">
            <div class="title-small-a">低成本创业必选</div>
            <span>
              <img class="title-small-b" :src="img3">
            </span>
          </div>
        </div>
        <div class="forms1" v-for="(form,index) in forms1" :key="index">
          <div class="form1" @click="select(form)">
            <div class="form1_icon">
              <img class="form1_img" :src="form.img">
            </div>
            <div class="form1_content">
              <div class="form1_title">{{form.title}}</div>
              <div class="form1_text">{{form.text}}</div>
            </div>
            <div class="form1_forw">
              <img class="forw1" :src="forw">
            </div>
          </div>
        </div>
      </div>
      <reserve></reserve>
      <!--横向logo-->
      <div class="link">
        <div class="title">
          <div class="title-big">业务范围</div>
          <div class="title-small">专注于为客户提供引领行业的开发服务</div>
        </div>
        <div class="apis">
          <div class="api" v-for="(api, index) in apis" :key="index">
            <div class="api_icon">
              <img :src="api.img" class="api_img">
            </div>
            <div class="api_text">{{api.text}}</div>
          </div>
        </div>
      </div>
      <reserve></reserve>
      <!--横向框-->
      <div class="link">
        <div class="title">
          <div class="title-big">解决方案</div>
          <div class="title-small">行业分析经验之战！专业，专注！— 全购</div>
        </div>
        <div class="forms2">
          <div class="form2" v-for="(form,index) in forms2" :key=index>
              <div class="form2_icon">
                <img class="form2_img" :src="form.img">
              </div>
              <div class="form2_content">
                <div class="form2_title">{{form.title}}</div>
                <div class="form2_text">{{form.text}}</div>
              </div>
              <div class="form2_forw">
                <i class="iconfont forw2">&#xe65f;</i>
              </div>
          </div>
        </div>
      </div>
      <reserve></reserve>
      <div class="link">
        <div class="title">
          <div class="title-big">项目流程</div>
          <div class="title-small">提供一体化的全程服务</div>
        </div>
        <div class="home2">
          <img class="img" :src="img2">
        </div>
      </div>
      <Reserve></Reserve>
      <!--flex logo-->
      <div class="coplogo">
        <div class="coplogo_content">
          <div class="coplogo_title">强强联合共筑闭环</div>
          <div class="coplogo_text">与国内知名企业都有合作，为您带来更优质的服务</div>
        </div>
        <div class="coplogo_icons">
          <div class="coplogo_icon">
            <img class="coplogo_img" src="@/assets/img/cop1.png">
          </div>
          <div class="coplogo_icon">
            <img class="coplogo_img" src="@/assets/img/cop2.png">
          </div>
          <div class="coplogo_icon">
            <img class="coplogo_img" src="@/assets/img/cop3.png">
          </div>
          <div class="coplogo_icon">
            <img class="coplogo_img" src="@/assets/img/cop4.png">
          </div>
          <div class="coplogo_icon">
            <img class="coplogo_img" src="@/assets/img/cop5.png">
          </div>
          <div class="coplogo_icon">
            <img class="coplogo_img" src="@/assets/img/cop6.png">
          </div>
          <div class="coplogo_icon">
            <img class="coplogo_img" src="@/assets/img/cop7.png">
          </div>
          <div class="coplogo_icon">
            <img class="coplogo_img" src="@/assets/img/cop8.png">
          </div>
          <div class="coplogo_icon">
            <img class="coplogo_img" src="@/assets/img/cop9.png">
          </div>
        </div>
        <div class="coplogo_solid"></div>
      </div>
      <Record></Record>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '../components/header.vue'
import Reserve from '../components/reserve.vue'
import Record from '../components/record.vue'
import Footer from '../components/footer.vue'

export default {
  name: 'Home',
  components: {
    Header,
    Reserve,
    Record,
    Footer
  },
  data () {
    return {
      img1: require('@/assets/img/home1.png'),
      img2: require('@/assets/img/home2.png'),
      img3: require('@/assets/img/热门.png'),
      forw: require('@/assets/img/前进@2x.png'),
      forms1: [{
        id: '8',
        img: require('@/assets/img/form1@2x.png'),
        title: '品牌商城系统',
        text: '品牌商城类小程序/APP定制开发，特价支持'
      }, {
        id: '7',
        img: require('@/assets/img/form2@2x.png'),
        title: '连锁门店系统',
        text: '连锁门店小程序/APP定制开发，仅仅1.98万起'
      }, {
        id: '6',
        img: require('@/assets/img/form3@2x.png'),
        title: '行业一码通',
        text: '行业一码通定制，所有对外服务窗口'
      }, {
        id: '5',
        img: require('@/assets/img/form4@2x.png'),
        title: '酒店管理系统',
        text: '系统定制开发，多语言版本，支持连锁酒店'
      }, {
        id: '4',
        img: require('@/assets/img/form5@2x.png'),
        title: '智能终端采集分析系统',
        text: '本软件为一个针对智能终端的各类数据'
      }, {
        id: '3',
        img: require('@/assets/img/form6@2x.png'),
        title: '劳务众包用工管理系统',
        text: '实现以灵活用工模式进行统一管理'
      }, {
        id: '2',
        img: require('@/assets/img/form7@2x.png'),
        title: '行业ERP定制开发',
        text: '支持100人以上远程协同办公定制价格19.8万起'
      }, {
        id: '1',
        img: require('@/assets/img/form8@2x.png'),
        title: '小程序/APP/WEB定制开发',
        text: '按需定制，价格透明'
      }],
      forms2: [{
        img: require('@/assets/img/form2.1.png'),
        title: '数字企业法人',
        text: '所有权与经营权分离，法人资产数字化监管，异地决策投票'
      }, {
        img: require('@/assets/img/form2.2.png'),
        title: '智慧远程协同办公方案',
        text: '目标数字化管理，组织结构云端数字化，云端人力资源管理，智能法务，智慧财务，异地协同办公'
      }, {
        img: require('@/assets/img/form2.3.png'),
        title: '多渠道品牌营销解决方案',
        text: '线上品牌门店超市，流量入口，直播中心，招商中心'
      }],
      apis: [{
        img: require('@/assets/img/api1.png'),
        text: 'APP开发'
      }, {
        img: require('@/assets/img/api2.png'),
        text: '微信开发'
      }, {
        img: require('@/assets/img/api3.png'),
        text: 'SAAS软件开发'
      }, {
        img: require('@/assets/img/api4.png'),
        text: '运营策划于支持'
      }]
    }
  },
  methods: {
    select (form) {
      this.$router.push({
        path: `/page/${form.id}`
      })
    },
    reserve () {
      this.$router.push({
        path: '/nowreserve'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.body {
  position: relative;
  z-index: 1;
  margin: 0.42rem -0.08rem 0 -0.08rem;
}
.home1 {
 position: relative;
 margin-bottom: 0.2rem;
 .reserve {
  position: absolute;
  bottom: 0;
  left: 1.25rem;
  width: 1.25rem;
  height: 0.32rem;
  line-height: 0.32rem;
  background: #ffffff;
  color: #3974FB;
  border-radius: 0.18rem;
  text-align: center;
  margin: 0.2rem auto;
  font-family: PingFangSC-Medium;
}
}
.home2 {
  margin: 0 0.1rem 0 0.1rem;
}
.img {
 width: 100%;
}
.link {
  text-align: center;
  .title {
    margin-bottom: 0.2rem;
    .title-big {
      font-size: 0.17rem;
      font-family: PingFangSC-Medium;
      color: #1e1e1e;
      margin-bottom: 0.02rem;
    }
    .title-small {
      font-family: PingFangSC-Regular;
      color: #666666;
      display: flex;
      justify-content: center;
      .title-small-a {
        margin-right: 0.06rem;
      }
      .title-small-b {
        width: 0.09rem;
      }
    }
  }
  .apis {
    display: flex;
    justify-content: space-around;
    .api {
      .api_img {
        width: 0.42rem;
      }
    }
  }
}
.forms1 {
 margin: 0.16rem 0.16rem  0 0.16rem;
  .form1 {
    position: relative;
    display: flex;
    align-items: center;
    height: 0.7rem;
    margin-bottom: 0.05rem;
    border-radius: 0.08rem;
    box-shadow: 0 0.01rem 0.04rem 0 rgba(0, 0, 0, 0.16);
    .form1_icon {
      margin: 0 0.12rem;
      width: 0.33rem;
      .form1_img {
        width:100%
      }
    }
    .form1_content {
      text-align: start;
      margin-right: 0.4rem;
      .form1_title {
        margin-bottom: 0.04rem;
        font-family: PingFangSC-Medium;
        font-size: 0.14rem;
      }
      .form1_text {
        font-family: PingFangSC-Regular;
        font-size: 0.12rem;
        color: #777777
      }
    }
    .form1_forw {
      position: absolute;
      right: 0.1rem;
      .forw1 {
        height: 0.16rem;
      }
    }
  }
}
.forms2 {
  margin: 0 0.16rem;
  display: flex;
  justify-content: space-around;
  .form2 {
    position: relative;
    text-align: start;
    width: 1.09rem;
    height: 2.415rem;
    border-radius: 0.08rem;
    box-shadow: 0 0.01rem 0.04rem 0 rgba(0, 0, 0, 0.16);
    .form2_icon {
      margin: 0.16rem 0 0 0.11rem;
      width: 0.24rem;
      .form2_img {
        width: 100%;
      }
    }
    .form2_content {
      margin: 0.05rem 0.12rem 0 0.11rem;
      .form2_title {
        height: 0.4rem;
        font-family: PingFangSC-Medium;
      }
      .form2_text {
        font-family: PingFangSC-Regular;
        color: #888888
      }
    }
    .form2_forw {
      position: absolute;
      border: 0.01rem solid #3974FB;
      width: 0.13rem;
      height: 0.13rem;
      border-radius: 50%;
      left: 0.48rem;
      bottom: 0.16rem;
      .forw2 {
        position: absolute;
        left: 0.01rem;
        font-size: 0.05rem;
      }
    }
  }
}
.coplogo {
  position: relative;
  height: 2.455rem;
  background: #f3f3f3;
  .coplogo_content {
    padding: 0.2rem 0.48rem 0 0.48rem;
    text-align: center;
    .coplogo_title {
      font-family: PingFangSC-Medium;
      font-size: 0.17rem;
      margin-bottom: 0.06rem
    }
    .coplogo_text {
      font-family: PingFangSC-Regular;
      color: #666666;
    }
  }
  .coplogo_icons {
    margin: 0.28rem 0 0 0.47rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .coplogo_icon {
      margin: 0 0.36rem 0.2rem 0;
      width: 0.69rem;
      .coplogo_img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .coplogo_solid {
    position: absolute;
    bottom: 0;
    left: 0.16rem;
    right: 0.18rem;
    border: 0.005rem solid #e1e1e1;
  }
}
</style>
